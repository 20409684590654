.topSection {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 30px auto 70px auto;

  @media (max-width: 600px) {
    margin-bottom: 50px;
  }
}

.logo {
  max-height: 70px;
  max-width: 70px;
}

.formFieldLabel {
  font-family: 'EkoTitle', 'Philosopher';
  font-weight: bold;
  font-size: 24px;
  text-align: left;
  max-width: 463px;

  @media (max-width: 1200px) {
    font-size: 18px;
  }

  @media (max-width: 600px) {
    max-width: 350px;
  }

  @media (max-width: 420px) {
    max-width: 300px;
  }
}

.inputBox {
  background: var(--eko-secondary-color);
  border-radius: 20px 20px 0px 0px;
  margin-top: 15px;

  width: 463px;
  max-width: 463px;

  @media (max-width: 600px) {
    width: 350px;
    max-width: 350px;
  }

  @media (max-width: 400px) {
    width: 100%;
    max-width: 300px;
  }
}

.inputBoxKYC {
  background: var(--eko-secondary-color);
  border-radius: 20px 20px 0px 0px;
  margin-top: 15px;
}

.slideContainerKYC {
  padding-top: 20px;

  @media (max-width: 600px) {
    padding-top: 25px;
  }
}

.slideContainer {
  display: flex;
  justify-content: center;

  @media (max-width: 470px) {
    padding-top: 28px;
  }
}

.textInput {
  font-family: Mulish;
  font-weight: normal;
  font-size: 16px !important;
  border: none !important;
  outline: none !important;
  border-bottom: 1px solid var(--eko-fourth-color) !important;
  min-width: 366px;
  width: 100% !important;
  padding-bottom: 8px;
  border-radius: 0 !important;
  box-shadow: none !important;

  @media (max-width: 1200px) {
    font-size: 14px !important;
    min-width: 318px;
  }

  @media (max-width: 600px) {
    min-width: 290px;
  }

  @media (max-width: 400px) {
    min-width: 200px;
  }
}

.textInput::placeholder {
  font-size: 16px !important;
  color: #9faaad !important;

  @media (max-width: 992px) {
    font-size: 14px !important;
  }
}

.textInputInvalid {
  border-bottom: 1px solid var(--eko-error-color) !important;
  color: var(--eko-error-color) !important;
}

.phoneInputContainer {
  outline: none !important;
}

.phoneInputButton, .phoneInputButton > div {
  background-color: transparent !important;
  background: transparent !important;
  border: none !important;
  border-radius: 0 !important;
}

.selectInput {
  font-family: Mulish;
  font-weight: normal;
  font-size: 16px;
  border: none;
  outline: none;
  border: 1px solid var(--eko-fourth-color);;
  min-width: 366px;
  width: 100%;
  border-radius: 0 !important;
  color: black !important;
  background: white !important;

  @media (max-width: 1200px) {
    font-size: 14px;
    min-width: 318px;
  }

  @media (max-width: 600px) {
    min-width: 70vw;
  }
}

.inputContainer {
  padding: 40px 40px 48px 40px;

  @media (max-width: 600px) {
    padding: 30px 30px 38px 30px;
  }
}

.btnsBar {
  background: var(--eko-primary-color);
  border-radius: 0px 0px 20px 20px;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}

.btnsBar button {
  font-family: Mulish;
  font-weight: bold;
  font-size: 18px;
  color: var(--eko-secondary-color);
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1200px) {
    font-size: 16px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
  }
}

.arrowIcon {
  width: 25px !important;
  margin: 0 7px;

  @media (max-width: 600px) {
    width: 15px !important;
  }
}

.enterBtn {
  font-family: Mulish;
  font-weight: normal;
  font-size: 14px;
  color: var(--eko-third-color);
  border: none;
  background: none;
  float: right;
  margin-top: 15px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
}

.enterBtn img {
  width: 10px !important;
  margin-left: 5px;
}

.stepNo {
  background: var(--eko-fifth-color);
  width: 35px;
  height: 35px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Mulish;
  font-weight: bold;
  font-size: 16px;
  color: var(--eko-third-color-light);

  @media (max-width: 600px) {
    font-size: 13px;
    width: 27px;
    height: 27px;
  }
}

.stepNoActive {
  border: 3px solid var(--eko-primary-color);
  color: var(--eko-primary-color);
}

.stepsCompleted {
  background: var(--eko-primary-color);
  border: 3px solid var(--eko-primary-color);
  color: var(--eko-secondary-color);
}

.numbersContainer {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 900px;
  top: -7px;

  @media (max-width: 992px) {
    width: 85vw;
  }

  @media (max-width: 600px) {
    top: -3px;
  }
}

.headerContainer {
  width: 1082px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1200px) {
    width: 950px;
  }

  @media (max-width: 990px) {
    width: 715px;
  }

  @media (max-width: 830px) {
    width: 84vw;
  }
}

.barsContainer {
  width: 730px;
  column-gap: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 830px) {
    width: 84vw;
  }
}

.progressBar {
  height: 10px;
  margin-top: 8px;
  width: 100%;
  border-radius: 0;
}

.middleBar {
  height: 10px;
  margin-top: 8px;
  width: 78px;
  border-radius: 0 !important;

  @media (max-width: 830px) {
    width: 9vw;
  }

  @media (max-width: 600px) {
    height: 7px;
  }
}

.endBar {
  height: 10px;
  margin-top: 8px;
  width: 78px;
  border-top-right-radius: 10 !important;
  border-bottom-left-radius: 10 !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;

  @media (max-width: 830px) {
    width: 9vw;
  }

  @media (max-width: 600px) {
    height: 7px;
  }
}

.inputErrorMsg {
  font-family: Mulish;
  font-weight: normal;
  font-size: 14px;
  color: var(--eko-error-color);
  margin-top: 10px;
  text-align: left;
}

::-webkit-input-placeholder {
  text-transform: initial;
}

:-moz-placeholder {
  text-transform: initial;
}

::-moz-placeholder {
  text-transform: initial;
}

:-ms-input-placeholder {
  text-transform: initial;
}

.phoneInput {
  border: none;
  outline: none;
}

.phoneInput::placeholder {
  font-size: 18px;
  color: var(--eko-fourth-color);;

  @media (max-width: 600px) {
    font-size: 14px;
  }
}

.fileUploadComp {
  border: 1px dashed var(--eko-third-color);
  box-sizing: border-box;
  background: #f8f8f8;
  height: 164px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  @media (max-width: 600px) {
    font-size: 14px;
    min-width: unset;
    height: 130px;
  }
}

.fileUploadComp.highlight {
  border-color: purple;
}

.fileInput {
  opacity: 0 !important;
}

.fileUploadComp img {
  width: 75px !important;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    width: 62px !important;
    margin-bottom: 15px;
  }
}

.fileUploadComp label {
  background-color: transparent;
  color: var(--eko-fourth-color);
}

.attachLabel {
  font-family: Mulish;
  font-weight: normal;
  font-size: 16px;
  color: var(--eko-third-color);
  text-align: left;
  margin-bottom: 5px;

  @media (max-width: 600px) {
    font-size: 14px;
  }
}

.uploadRequirements {
  font-family: Mulish;
  font-weight: normal;
  font-size: 12px;
  color: var(--eko-third-color);
  margin-top: 5px;
  text-align: left;
}

.radioGroupTitle {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
  margin-bottom: 13px;
  margin-top: 20px;
  max-width: 450px;

  @media (max-width: 992px) {
    font-size: 16px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
  }
}

.radioOption {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-right: 30px;
  margin-left: -14px;

  @media (max-width: 600px) {
    font-size: 14px;
  }
}

.radioOption label {
  margin: 0;
  margin-right: 12px;
  display: flex;
  align-items: center;
}

.radioOption > input[type="radio"] {
  opacity: 0;
}

.radioOption > input[type="radio"] + *::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  border-style: solid;
  border-color: gray;
  margin-right: 12px;
}

.radioOption > input[type="radio"]:focus + *::before {
  border-color: var(--eko-primary-color);
}

.radioOption > input[type="radio"]:checked + *::before {
  background: radial-gradient(var(--eko-primary-color) 0%, var(--eko-primary-color) 40%, transparent 50%, transparent);
  border-color: var(--eko-primary-color);
}
.radioOption > input[type="radio"]:checked + * {
  color: var(--eko-primary-color);
}

.thankyouMsg {
  font-family: 'EkoTitle', 'Philosopher';
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 30px;

  @media (max-width: 992px) {
    font-size: 18px;
  }
}

.agreementMsg {
  font-family: 'EkoTitle', 'Philosopher';
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 30px;
  margin-top: 30px;
  text-align: left;

  @media (max-width: 992px) {
    font-size: 18px;
  }
}

.confirmLabel {
  font-family: Mulish;
  font-weight: bold;
  font-size: 16px;
  color: var(--eko-primary-color);
  text-align: left;
}

.confirmContainer {
  background: white;
  padding: 40px;
  border-radius: 16px;

  @media (max-width: 992px) {
    margin-bottom: 30px;
    height: unset;
    padding: 30px;
    height: unset !important;
  }

  @media (max-width: 600px) {
    margin-bottom: 20px;
  }
}

.infoBox {
  display: flex;
  align-items: center;
  margin-top: 15px;
  cursor: pointer;

  @media (max-width: 600px) {
    margin-top: 10px;
  }
}

.infoBox > img {
  display: none;
}

.infoBox:hover > img {
  display: block;
  width: 15px;
  margin-left: 15px;
}

.infoBox:hover > div {
  text-decoration: underline;
}

.infoboxlabel {
  font-family: Mulish;
  font-weight: bold;
  font-size: 16px;
  margin: 0;
  text-align: left;

  @media (max-width: 600px) {
    font-size: 14px;
  }
}

.infoBoxValue {
  font-family: Mulish;
  font-weight: normal;
  font-size: 16px;
  text-align: left;

  @media (max-width: 600px) {
    font-size: 14px;
  }
}

.infoCol {
  @media (min-width: 992px) {
    padding: 0 !important;
  }
}

.finishBtn {
  background: var(--eko-fourth-color);
  border-radius: 18px;
  border: none;
  outline: none;
  font-family: Mulish;
  font-weight: normal;
  font-size: 18px;
  color: var(--eko-secondary-color);
  margin: 30px 0;
  padding: 24px 100px;

  @media (max-width: 992px) {
    font-size: 18px;
    padding: 19px 83px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
    padding: 19px 83px;
  }
}

.finishBtnDisabled {
  background: var(--eko-fifth-color);
  border-radius: 18px;
  border: none;
  outline: none;
  font-family: Mulish;
  font-weight: normal;
  font-size: 18px;
  color: var(--eko-third-color);
  margin: 30px 0;
  padding: 24px 100px;

  @media (max-width: 992px) {
    font-size: 18px;
    padding: 19px 83px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
    padding: 19px 83px;
  }
}

.error {
  color: red;
  font-size: 18px;
  margin-top: 20px;
}

.textInputLabel {
  font-family: Mulish;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
  margin-bottom: 15px;

  @media (max-width: 992px) {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.fieldTopMargin {
  margin-top: 25px;

  @media (max-width: 992px) {
    font-size: 20px;
  }
}

.subVisaNote {
  font-family: Mulish;
  font-weight: normal;
  font-size: 16px;
  color: var(--eko-third-color);
  margin-top: 10px;
  text-align: left;
}

.docDesc {
  font-family: Mulish;
  font-weight: normal;
  font-size: 16px;
  color: var(--eko-fourth-color);;
  text-align: left;
  line-height: 150%;
  margin-bottom: 20px;
  max-width: 350px;

  @media (max-width: 600px) {
    max-width: 296px;
    font-size: 14px;
  }
}

.info {
  position: relative;
  cursor: pointer;
}

/* Show the tooltip on hover */
.info:hover .infoTooltip {
  visibility: visible;
  opacity: 1;
}

.infoTooltip {
  font-size: 14px;
  color: var(--eko-third-color);
  background-color: white;

  position: absolute;
  width: 450px;
  left: -71%;
  bottom: 90%;
  margin-bottom: 15px;
  padding: 15px;
  border: 2px solid #eaeaea;
  border-radius: 10px;

  /* Hide the tooltip by default */
  visibility: hidden;
  opacity: 0;
  text-align: left;

  @media (max-width: 950px) {
    left: -500%;
  }

  @media (max-width: 660px) {
    left: -650%;
  }

  @media (max-width: 470px) {
    width: 330px;
    left: -558%;
  }
}

.infoTooltip::before,
.infoTooltip::after {
  content: "";
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  top: 100%;
  left: 10%;
  margin-left: -10px;

  @media (max-width: 950px) {
    left: 43%;
  }

  @media (max-width: 660px) {
    left: 54%;
  }

  @media (max-width: 470px) {
    left: 64%;
  }
}

.infoTooltip::before {
  border-top: 10px solid #eaeaea;
  margin-top: 1px;
}

/* The white fill of the triangle */
.infoTooltip::after {
  border-top: 10px solid white;
  margin-top: -2px;
  z-index: 1;
}

.infoIcon {
  width: 24px !important;
  height: 24px !important;
  margin-left: 10px;
}

.employmentNote {
  font-family: Mulish;
  font-weight: normal;
  font-size: 14px;
  color: var(--eko-third-color);
  text-align: left;
  margin-top: 10px;
}

.confirmDisclosureSection {
  background-color: white;
  border-radius: 16px;

  margin-top: 30px;

  @media (max-width: 600px) {
    margin-top: 10px;
  }
}

.alpacaTable {
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 20px;
  }
}

.disclosureLabel {
  font-family: Mulish;
  font-weight: bold;
  font-size: 16px;
  padding: 20px;

  @media (max-width: 600px) {
    padding: 15px;
    font-size: 14px;
  }
}

.disclosureValue {
  font-family: Mulish;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  width: 120px;

  @media (max-width: 600px) {
    display: none;
  }
}

.disclosureValue > div > img {
  display: none;
}

.disclosureValue:hover > div > img {
  display: block;
  width: 15px;
  margin-left: 5px;
}

.disclosureValue:hover {
  text-decoration: underline;
  cursor: pointer;
}

.disclosureValueInside {
  font-family: Mulish;
  font-weight: normal;
  font-size: 14px;

  @media (min-width: 600px) {
    display: none;
  }
}

.disclosureValueInside > div > img {
  display: none;
}

.disclosureValueInside:hover > div > img {
  display: block;
  width: 15px;
  margin-left: 5px;
}

.disclosureValueInside:hover {
  text-decoration: underline;
  cursor: pointer;
}

.confirmDisclosureSectionContainer {
  margin-top: 20px;
  min-height: unset !important;

  @media (max-width: 992px) {
    margin-top: 0;
  }
}

.accordianLabel {
  background: var(--eko-fifth-color);
  font-family: Mulish;
  font-weight: normal;
  font-size: 18px;
  color: var(--eko-fourth-color);;
  border-radius: 10px;
  padding: 20px 25px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0;

  @media (max-width: 600px) {
    font-size: 14px;
    padding: 20px 15px;
  }
}

.accordianLabelActive {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordianLabel img {
  width: 19px !important;

  @media (max-width: 600px) {
    width: 10px !important;
  }
}

.accordianContent {
  height: 0;
  overflow: hidden;
  transition: 0.5s;
  padding-right: 30px;
  padding-left: 30px;
  border-radius: 0px 0px 10px 10px;

  @media (max-width: 600px) {
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.accordianContentActive {
  background-color: white;
  height: fit-content;
  overflow: hidden;
  padding: 20px 30px;

  @media (max-width: 600px) {
    font-size: 14px;
    padding: 15px;
  }
}

.dCol {
  @media (min-width: 992px) {
    padding: 0 !important;
  }
}

.radioLabel {
  font-size: 16px;
  max-width: 350px;
  text-align: left;

  @media (max-width: 600px) {
    font-size: 14px;
    max-width: 250px;
  }
}

.radioOptionLabelContianer {
  margin: 0 !important;
  margin-right: 0 !important;
  display: flex !important;
  align-items: center !important;
  margin-top: 20px !important;
}

.aggrementDropdownIconBtn {
  display: flex;
  padding: 20px;

  @media (max-width: 600px) {
    padding: 10px;
  }
}

.kycOuterContainer {
  border: 1px solid rgba(106, 109, 116, 0.47);
  box-sizing: border-box;
  border-radius: 15px;
  width: 776px;
  margin: 0 auto;
  padding: 30px 100px;
  margin-bottom: 20px;

  @media (max-width: 992px) {
    width: 80%;
    padding: 30px 60px;
  }

  @media (max-width: 600px) {
    width: 95%;
    padding: 30px 20px;
  }
}

.kycTitle {
  font-family: 'EkoTitle', 'Philosopher';
  font-weight: bold;
  font-size: 38px;
  text-align: center;
  color: var(--eko-primary-color);
  margin-bottom: 30px;

  @media (max-width: 992px) {
    font-size: 36px;
  }

  @media (max-width: 600px) {
    font-size: 24px;
    margin-bottom: 25px;
  }
}

.kycText {
  font-family: Mulish;
  font-weight: bold;
  font-size: 18px;
  text-align: left;
  margin-bottom: 30px;

  @media (max-width: 992px) {
    font-size: 16px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
    margin-bottom: 25px;
  }
}

.kycSubText {
  font-family: Mulish;
  font-weight: normal;
  font-size: 18px;
  text-align: left;
  margin-bottom: 30px;

  @media (max-width: 992px) {
    font-size: 16px;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    margin-bottom: 25px;
  }
}

.ekoEmail {
  color: var(--eko-primary-color);
  text-decoration: underline;
}
.ekoEmail:hover {
  color: var(--eko-primary-color);
}

.crossBtn {
  background: none;
  border: none;
  outline: none;
  width: 15px;
  height: 15px;
}

.crossBtn > img {
  width: 15px !important;
  height: 15px !important;
  margin: 0 !important;
}
